import { AccountViewRoutePath, ActiveNavMatcher, NavDetails, RoutePath } from './type';
import { productFeatureFlag } from 'account/src/configs/FeatureFlags';
import { BookKeeperIcon, BusinessIcon, ContactsIcon, DollarIcon, HomeIcon } from '@myob/myob-widgets';

const billingAndPaymentsItems = (isAccountView): NavDetails[] => {
  return [
    {
      key: 'Bills',
      label: 'Bills',
      url: isAccountView ? AccountViewRoutePath.MyBills : RoutePath.MyBills,
    },
    {
      key: 'PaymentDetails',
      label: 'Payment details',
      url: isAccountView ? AccountViewRoutePath.PaymentDetails : RoutePath.PaymentProfile,
    },
    {
      key: 'Payments',
      label: 'Payments',
      url: isAccountView ? AccountViewRoutePath.Payments : RoutePath.Payments,
      invisible: true,
    },
  ];
};

const manageMyAccountItems = (): NavDetails[] => [
  {
    key: 'Account details',
    label: 'Account details',
    url: AccountViewRoutePath.ManageAccountAccountDetails,
  },
];

const manageMyProductsItems = (isAccountView): NavDetails[] => [
  {
    key: 'cancellation',
    label: 'Cancellation',
    url: RoutePath.Cancellation,
    invisible: true,
    disabled: isAccountView,
  },
];

const manageMySupportTickets = (): NavDetails[] => {
  const isPremiumUser = localStorage.getItem('isPremiumCustomer') === 'true';
  const supportItems = [
    {
      key: 'Contact support',
      label: 'Contact support',
      url: AccountViewRoutePath.ContactSupport,
    },
    {
      key: 'View support requests',
      label: 'View support requests',
      url: AccountViewRoutePath.ViewSupportRequests,
    },
    {
      key: 'Book Priority Support',
      label: 'Book Priority Support',
      url: AccountViewRoutePath.PremiumSupportBooking,
      invisible: !isPremiumUser,
    },
  ];

  return supportItems;
};

const buildNavigationItems = (isAccountView) => {
  const navigationItems = [
    {
      key: 'Home',
      label: 'Home',
      matcher: ActiveNavMatcher.Home,
      classSelector: 'dashboard',
      url: isAccountView ? AccountViewRoutePath.Home : RoutePath.Home,
      icon: HomeIcon,
    },
    {
      key: 'ManageAccount',
      label: 'Manage account',
      matcher: ActiveNavMatcher.ManageAccount,
      classSelector: 'my-account',
      url: AccountViewRoutePath.ManageAccountAccountDetails,
      items: manageMyAccountItems(),
      icon: BusinessIcon,
    },
    {
      key: 'Manage subscriptions',
      label: 'Manage subscriptions',
      matcher: ActiveNavMatcher.Products,
      classSelector: 'my-products',
      url: isAccountView ? AccountViewRoutePath.Product : RoutePath.ManageMyProducts,
      items: manageMyProductsItems(isAccountView),
      icon: BookKeeperIcon,
    },
    {
      key: 'Billing',
      label: 'Billing',
      matcher: ActiveNavMatcher.Billing,
      classSelector: 'billing-and-payments',
      items: billingAndPaymentsItems(isAccountView),
      icon: DollarIcon,
    },
  ];
  {
    navigationItems.push({
      key: 'Support',
      label: 'Support',
      matcher: ActiveNavMatcher.Support,
      classSelector: 'support',
      items: manageMySupportTickets(),
      icon: ContactsIcon,
    });
  }
  return navigationItems;
};

export const navigationTree = (isAccountView, isSoloAccount = false): NavDetails[] => {
  const navigationTree = buildNavigationItems(isAccountView);
  if (productFeatureFlag.isProductFeatureEnabled() && isSoloAccount) {
    return navigationTree.filter((item) => item.key !== 'Support');
  }
  return navigationTree;
};
