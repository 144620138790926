import React from 'react';
import { navigateToUrl } from 'single-spa';
import { auth } from '@my-account/tools';
import { SignOutIcon } from '@myob/myob-widgets';

const logoutHash = '#logout';

const handleOnLogoutPress = (event) => {
  if (event.key === 'Enter') {
    handleOnLogoutClick(event);
  }
};

const handleOnLogoutPressAccountView = (event) => {
  if (event.key === 'Enter') {
    handleOnLogoutClickAccountView(event);
  }
};

const handleOnContactDetailsPress = (event) => {
  if (event.key === 'Enter') {
    handleOnContactDetailsClick(event);
  }
};

const handleOnSecurityPress = (event) => {
  if (event.key === 'Enter') {
    handleOnSecurityClick(event);
  }
};

const handleOnSecurityClick = (event: React.MouseEvent) => {
  navigateToUrl('/account/security');
  event.preventDefault();
};

const handleOnContactDetailsClick = (event: React.MouseEvent) => {
  navigateToUrl('/account/contact-details');
  event.preventDefault();
};

const handleOnLogoutClickAccountView = (event: React.MouseEvent) => {
  auth.logout('/account');
  event.preventDefault();
};

const handleOnLogoutClick = (event: React.MouseEvent) => {
  auth.logout();
  event.preventDefault();
};

const buildSettingMenuItems = (isAccountView) => {
  const settingMenuItems = [
    {
      key: 'contactDetails',
      label: 'Contact details',
      url: '/account/contact-details',
      onKeyDown: handleOnContactDetailsPress,
      onClick: handleOnContactDetailsClick,
    },
    {
      key: 'security',
      label: 'Account security',
      url: '/account/security',
      onKeyDown: handleOnSecurityPress,
      onClick: handleOnSecurityClick,
    },
    {
      key: 'logout',
      label: 'Log out',
      icon: SignOutIcon,
      url: logoutHash,
      onKeyDown: isAccountView ? handleOnLogoutPressAccountView : handleOnLogoutPress,
      onClick: isAccountView ? handleOnLogoutClickAccountView : handleOnLogoutClick,
    },
  ];
  return settingMenuItems;
};

export const settingTree = (isAccountView) => {
  const settingTree = buildSettingMenuItems(isAccountView);
  return settingTree;
};
